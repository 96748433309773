<template lang="pug">
.row.mb-5
    .col-sm-12.col-md-8.offset-md-2.background-image-cover
    .col-sm-12.col-md-6.offset-md-3
        .card.shadow(style="top:-50px;")
            .card-body.px-4(v-if="jobArticle")
                h2.my-4 {{ jobArticle.title }}
                .lh-lg.my-4(v-html="jobArticle.body1")
                //- hr
                //- p.my-4 Publicatiedatum: {{ jobArticle.createdAt }}
            .card-body.px-4(v-else)
                br
                .row.p-5
                    .col
                        p Deze vacature bestaat niet meer
                .row.px-5
                    .col
                        router-link(
                            to="/"
                        )
                            .btn.btn-large.btn-outline-success.x-btn-background-light
                                span &lt;&lt; &nbsp;
                                span Terug naar de homepage
                br
                br
            //- .card-footer
</template>

<script>
import graphql from '../graphql'

const { apolloClient, queries } = graphql

export default {
    name : 'JobArticle',
    data () {
        return {
            jobArticle : null
        }
    },
    async beforeCreate () {
        const result = await apolloClient
            .query({ query: queries.jobArticle(this.$route.params.id) })
        this.jobArticle = result.data.JobArticle
    }
}
</script>

<style lang="scss">
.background-image-cover {
    background-image: url(../assets/cover_comp_twice.jpg);
    background-position-x: 0px;
    background-position-y: 0px;
    background-size: cover;
    height: 280px;
    background-repeat: no-repeat;
}

@media only screen and (min-width: 1025px) {
    .background-image-cover {
        background-position-y: -148px;
    }
}
</style>
